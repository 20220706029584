import { referentialApi } from './../wapi/referential-api';
import { createModule, mutation, action } from 'vuex-class-component';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { IEmployeeRole, IReferential, IBank } from '@/entity/shared/referential';
import { NU } from '@t/type';
import { ISalesAccount } from '@/entity/project/sales-account';

const VuexModule = createModule({
    namespaced: 'referential',
    strict: true
});

export class ReferentialStore extends VuexModule {
    private insurancePercentage: NU<IReferential[]> = [];
    private insuranceType: NU<IReferential[]> = [];
    private typo: NU<IReferential[]> = [];
    private phase: NU<IReferential[]> = [];
    private status: NU<IReferential[]> = [];
    private billingType: NU<IReferential[]> = [];
    private orderStatu: NU<IReferential[]> = [];
    private thirdPartyTypeRef: NU<IReferential[]> = [];
    private thirdPartyAddressTypeRef: NU<IReferential[]> = [];
    private bank: NU<IBank[]> = [];
    private paymentDueDate: NU<IReferential[]> = [];
    private salesAccount: NU<ISalesAccount[]> = [];
    private employeeRole: NU<IEmployeeRole[]> = [];
    private employeeService: NU<IReferential[]> = [];
    private employeeContract: NU<IReferential[]> = [];
    private workflowType: NU<IReferential[]> = [];
    private dayPartType: NU<IReferential[]> = [];
    private eventType: NU<IReferential[]> = [];

    private loadedState: any = {};

    get insurancePercentages(): NU<IReferential[]> {
        return this.insurancePercentage;
    }

    get insuranceTypes(): NU<IReferential[]> {
        return this.insuranceType;
    }

    get typos(): NU<IReferential[]> {
        return this.typo;
    }

    get phases(): NU<IReferential[]> {
        return this.phase;
    }
    get statuses(): NU<IReferential[]> {
        return this.status;
    }

    get billingTypes(): NU<IReferential[]> {
        return this.billingType;
    }

    get orderStatus(): NU<IReferential[]> {
        return this.orderStatu;
    }

    get thirdPartyType(): NU<IReferential[]> {
        return this.thirdPartyTypeRef;
    }

    get thirdPartyAddressType(): NU<IReferential[]> {
        return this.thirdPartyAddressTypeRef;
    }

    get banks(): NU<IBank[]> {
        return this.bank;
    }

    get salesAccounts(): NU<ISalesAccount[]> {
        return this.salesAccount;
    }

    get employeeRoles(): NU<IEmployeeRole[]> {
        return this.employeeRole;
    }

    get employeeServices(): NU<IReferential[]> {
        return this.employeeService;
    }

    get employeeContracts(): NU<IReferential[]> {
        return this.employeeContract;
    }

    get workflowTypes(): NU<IReferential[]> {
        return this.workflowType;
    }

    get dayPartTypes(): NU<IReferential[]> {
        return this.dayPartType;
    }

    get eventTypes(): NU<IReferential[]> {
        return this.eventType;
    }

    get paymentDueDates(): NU<IReferential[]> {
        return this.paymentDueDate;
    }

    @action
    public async fetchInsurancePercentages(): Promise<void> {
        if (!this.insurancePercentage || this.insurancePercentage.length === 0) {
            const datas = await referentialApi.getAllInsurancePercentages();
            if (isCallValidAndNotCancelled<IReferential[]>(datas)) {
                this.setInsurancePercentages(datas?.datas);
            }
        }
    }

    @action
    public async fetchInsuranceTypes(): Promise<void> {
        if (!this.insuranceType || this.insuranceType.length === 0) {
            const datas = await referentialApi.getAllInsuranceTypes();
            if (isCallValidAndNotCancelled<IReferential[]>(datas)) {
                this.setInsuranceTypes(datas?.datas);
            }
        }
    }

    @action
    public async fetchTypos(): Promise<void> {
        if (!this.typo || this.typo.length === 0) {
            const datas = await referentialApi.getAllTypos();
            if (isCallValidAndNotCancelled<IReferential[]>(datas)) {
                this.setTypos(datas?.datas);
            }
        }
    }

    @action
    public async fetchPhases(): Promise<void> {
        if (!this.phase || this.phase.length === 0) {
            const datas = await referentialApi.getAllPhases();
            if (isCallValidAndNotCancelled<IReferential[]>(datas)) {
                this.setPhases(datas?.datas);
            }
        }
    }

    @action
    public async fetchStatuses(): Promise<void> {
        if (!this.status || this.status.length === 0) {
            const datas = await referentialApi.getAllStatuses();
            if (isCallValidAndNotCancelled<IReferential[]>(datas)) {
                this.setStatuses(datas?.datas);
            }
        }
    }

    @action
    public async fetchBillingTypes(): Promise<void> {
        if (!this.billingType || this.billingType.length === 0) {
            const datas = await referentialApi.getAllBillingTypes();
            if (isCallValidAndNotCancelled<IReferential[]>(datas)) {
                this.setBillingTypes(datas?.datas);
            }
        }
    }

    @action
    public async fetchOrderStatus(): Promise<void> {
        if (!this.orderStatu || this.orderStatu.length === 0) {
            const datas = await referentialApi.getAllOrderStatus();
            if (isCallValidAndNotCancelled<IReferential[]>(datas)) {
                this.setOrderStatus(datas?.datas);
            }
        }
    }

    @action
    public async fetchThirdPartyType(): Promise<void> {
        if (!this.thirdPartyTypeRef || this.thirdPartyTypeRef.length === 0) {
            const datas = await referentialApi.getAllThirdPartyTypes();
            if (isCallValidAndNotCancelled<IReferential[]>(datas)) {
                this.setThirdPartyTypes(datas?.datas);
            }
        }
    }

    @action
    public async fetchThirdPartyAddressType(): Promise<void> {
        if (!this.thirdPartyAddressTypeRef || this.thirdPartyAddressTypeRef.length === 0) {
            const datas = await referentialApi.getAllThirdPartyAddressTypes();
            if (isCallValidAndNotCancelled<IReferential[]>(datas)) {
                this.setThirdPartyAddressTypes(datas?.datas);
            }
        }
    }

    @action
    public async fetchBanks(): Promise<void> {
        if (!this.bank || this.bank.length === 0) {
            const datas = await referentialApi.getAllBanks();
            if (isCallValidAndNotCancelled<IBank[]>(datas)) {
                this.setBanks(datas?.datas);
            }
        }
    }

    @action
    public async fetchPaymentDueDates(): Promise<void> {
        if (!this.paymentDueDate || this.paymentDueDate.length === 0) {
            const datas = await referentialApi.getAllPaymentDueDates();
            if (isCallValidAndNotCancelled<IReferential[]>(datas)) {
                this.setPaymentDueDates(datas?.datas);
            }
        }
    }

    @action
    public async fetchSalesAccount(): Promise<void> {
        if (!this.salesAccount || this.salesAccount.length === 0) {
            const datas = await referentialApi.getAllSalesAccount();
            if (isCallValidAndNotCancelled<ISalesAccount[]>(datas)) {
                this.setSalesAccount(datas?.datas);
            }
        }
    }

    @action
    public async fetchEmployeeRoles(): Promise<void> {
        if (!this.employeeRole || this.employeeRole.length === 0) {
            const datas = await referentialApi.getAllEmployeeRoles();
            if (isCallValidAndNotCancelled<IEmployeeRole[]>(datas)) {
                this.setEmployeeRoles(datas?.datas);
            }
        }
    }

    @action
    public async fetchEmployeeServices(): Promise<void> {
        if (!this.employeeService || this.employeeService.length === 0) {
            const datas = await referentialApi.getAllEmployeeServices();
            if (isCallValidAndNotCancelled<IReferential[]>(datas)) {
                this.setEmployeeServices(datas?.datas);
            }
        }
    }

    @action
    public async fetchEmployeeContracts(): Promise<void> {
        if (!this.employeeContract || this.employeeContract.length === 0) {
            const datas = await referentialApi.getAllEmployeeContracts();
            if (isCallValidAndNotCancelled<IReferential[]>(datas)) {
                this.setEmployeeContracts(datas?.datas);
            }
        }
    }

    @action
    public async fetchWorkflowTypes(): Promise<void> {
        if (!this.workflowType || this.workflowType.length === 0) {
            const datas = await referentialApi.getAllWorkflowTypes();
            if (isCallValidAndNotCancelled<IReferential[]>(datas)) {
                this.setWorkflowTypes(datas?.datas);
            }
        }
    }

    @action
    public async fetchDayPartTypes(): Promise<void> {
        if (!this.dayPartType || this.dayPartType.length === 0) {
            const datas = await referentialApi.getAllDayPartTypes();
            if (isCallValidAndNotCancelled<IReferential[]>(datas)) {
                this.setDayPartTypes(datas?.datas);
            }
        }
    }

    @action
    public async fetchEventTypes(): Promise<void> {
        if (!this.eventType || this.eventType.length === 0) {
            const datas = await referentialApi.getAllEventTypes();
            if (isCallValidAndNotCancelled<IReferential[]>(datas)) {
                this.setEventTypes(datas?.datas);
            }
        }
    }

    @mutation
    private setInsurancePercentages(state: NU<IReferential[]>) {
        this.insurancePercentage = state;
    }

    @mutation
    private setInsuranceTypes(state: NU<IReferential[]>) {
        this.insuranceType = state;
    }

    @mutation
    private setTypos(state: NU<IReferential[]>) {
        this.typo = state;
    }

    @mutation
    private setPhases(state: NU<IReferential[]>) {
        this.phase = state;
    }

    @mutation
    private setStatuses(state: NU<IReferential[]>) {
        this.status = state;
    }

    @mutation
    private setBillingTypes(state: NU<IReferential[]>) {
        this.billingType = state;
    }

    @mutation
    private setOrderStatus(state: NU<IReferential[]>) {
        this.orderStatu = state;
    }

    @mutation
    private setThirdPartyTypes(state: NU<IReferential[]>) {
        this.thirdPartyTypeRef = state;
    }

    @mutation
    private setThirdPartyAddressTypes(state: NU<IReferential[]>) {
        this.thirdPartyAddressTypeRef = state;
    }

    @mutation
    private setBanks(state: NU<IBank[]>) {
        this.bank = state;
    }

    @mutation
    private setPaymentDueDates(state: NU<IReferential[]>) {
        this.paymentDueDate = state;
    }

    @mutation
    private setSalesAccount(state: NU<ISalesAccount[]>) {
        this.salesAccount = state;
    }

    @mutation
    private setEmployeeRoles(state: NU<IEmployeeRole[]>) {
        this.employeeRole = state;
    }

    @mutation
    private setEmployeeServices(state: NU<IReferential[]>) {
        this.employeeService = state;
    }

    @mutation
    private setEmployeeContracts(state: NU<IReferential[]>) {
        this.employeeContract = state;
    }

    @mutation
    private setWorkflowTypes(state: NU<IReferential[]>) {
        this.workflowType = state;
    }

    @mutation
    private setDayPartTypes(state: NU<IReferential[]>) {
        this.dayPartType = state;
    }

    @mutation
    private setEventTypes(state: NU<IReferential[]>) {
        this.eventType = state;
    }
}

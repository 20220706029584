import { IStudio } from '@/entity/project/studio';
import { IAgency } from '@/entity/project/agency';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';
import { IBank, IEmployeeRole, IReferential } from '@/entity/shared/referential';
import { ISupplier } from '@/entity/shared/supplier';
import { ISalesAccount } from '@/entity/project/sales-account';

class ReferentialApi {
    /**
     *
     */
    url: string = 'api/referential';

    public async getAllInsurancePercentages(): Promise<ICancellableResult<IReferential[]>> {
        return await ajaxCall.baseAjaxCall<IReferential[]>('get', this.url, { urlParameter: ['insurancepercentages'] });
    }

    public async getAllInsuranceTypes(): Promise<ICancellableResult<IReferential[]>> {
        return await ajaxCall.baseAjaxCall<IReferential[]>('get', this.url, { urlParameter: ['insurancetypes'] });
    }

    public async patchInsuranceTypes(data: IReferential[]): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean, IReferential[]>('patch', this.url, {
            urlParameter: ['saveinsurancetypes'],
            payload: data
        });
    }

    public async getAllTypos(): Promise<ICancellableResult<IReferential[]>> {
        return await ajaxCall.baseAjaxCall<IReferential[]>('get', this.url, { urlParameter: ['typos'] });
    }

    public async getAllPhases(): Promise<ICancellableResult<IReferential[]>> {
        return await ajaxCall.baseAjaxCall<IReferential[]>('get', this.url, { urlParameter: ['phases'] });
    }

    public async getAllStatuses(): Promise<ICancellableResult<IReferential[]>> {
        return await ajaxCall.baseAjaxCall<IReferential[]>('get', this.url, { urlParameter: ['statuses'] });
    }


    public async getAllBillingTypes(): Promise<ICancellableResult<IReferential[]>> {
        return await ajaxCall.baseAjaxCall<IReferential[]>('get', this.url, { urlParameter: ['billingtypes'] });
    }

    public async getAllOrderStatus(): Promise<ICancellableResult<IReferential[]>> {
        return await ajaxCall.baseAjaxCall<IReferential[]>('get', this.url, { urlParameter: ['orderstatus'] });
    }

    public async getAllThirdPartyTypes(): Promise<ICancellableResult<IReferential[]>> {
        return await ajaxCall.baseAjaxCall<IReferential[]>('get', this.url, { urlParameter: ['thirdpartytypes'] });
    }

    public async getAllThirdPartyAddressTypes(): Promise<ICancellableResult<IReferential[]>> {
        return await ajaxCall.baseAjaxCall<IReferential[]>('get', this.url, { urlParameter: ['thirdpartyaddresstypes'] });        
    }

    public async getAllBanks(): Promise<ICancellableResult<IBank[]>> {
        return await ajaxCall.baseAjaxCall<IBank[]>('get', this.url, { urlParameter: ['banks'] });
    }

    public async patchBanks(data: IBank[]): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean, IBank[]>('patch', this.url, {
            urlParameter: ['savebanks'],
            payload: data
        });
    }

    public async getAllEmployeeRoles(): Promise<ICancellableResult<IEmployeeRole[]>> {
        return await ajaxCall.baseAjaxCall<IEmployeeRole[]>('get', this.url, { urlParameter: ['employeeroles'] });
    }

    public async getAllPaymentDueDates(): Promise<ICancellableResult<IReferential[]>> {
        return await ajaxCall.baseAjaxCall<IEmployeeRole[]>('get', this.url, { urlParameter: ['paymentduedates'] });
    }

    public async patchEmployeeRoles(data: IEmployeeRole[]): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean, IEmployeeRole[]>('patch', this.url, {
            urlParameter: ['saveemployeeroles'],
            payload: data
        });
    }

    public async getAllAgencies(): Promise<ICancellableResult<IAgency[]>> {
        return await ajaxCall.baseAjaxCall<IAgency[]>('get', this.url, { urlParameter: ['agencies'] });
    }

    public async getAllSalesAccount(): Promise<ICancellableResult<ISalesAccount[]>> {
        return await ajaxCall.baseAjaxCall<ISalesAccount[]>('get', this.url, { urlParameter: ['salesAccount'] });
    }

    public async patchAgencies(data: IAgency[]): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean, IAgency[]>('patch', this.url, {
            urlParameter: ['saveagencies'],
            payload: data
        });
    }

    public async patchSalesAccount(data: ISalesAccount[]): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean, ISalesAccount[]>('patch', this.url, {
            urlParameter: ['savesales'],
            payload: data
        });
    }

    public async getAllStudios(): Promise<ICancellableResult<IStudio[]>> {
        return await ajaxCall.baseAjaxCall<IStudio[]>('get', this.url, { urlParameter: ['studios'] });
    }

    public async patchStudios(data: IStudio[]): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean, IStudio[]>('patch', this.url, {
            urlParameter: ['savestudios'],
            payload: data
        });
    }

    public async getAllEmployeeServices(): Promise<ICancellableResult<IReferential[]>> {
        return await ajaxCall.baseAjaxCall<IReferential[]>('get', this.url, { urlParameter: ['employeeservices'] });
    }

    public async getAllEmployeeContracts(): Promise<ICancellableResult<IReferential[]>> {
        return await ajaxCall.baseAjaxCall<IReferential[]>('get', this.url, { urlParameter: ['employeecontracts'] });
    }

    public async getAllWorkflowTypes(): Promise<ICancellableResult<IReferential[]>> {
        return await ajaxCall.baseAjaxCall<IReferential[]>('get', this.url, { urlParameter: ['workflowtypes'] });
    }

    public async getAllDayPartTypes(): Promise<ICancellableResult<IReferential[]>> {
        return await ajaxCall.baseAjaxCall<IReferential[]>('get', this.url, { urlParameter: ['dayparttypes'] });
    }

    public async getAllEventTypes(): Promise<ICancellableResult<IReferential[]>> {
        return await ajaxCall.baseAjaxCall<IReferential[]>('get', this.url, { urlParameter: ['eventtypes'] });
    }

    public async getByIdAndCode(id: string, code: string): Promise<ICancellableResult<ISupplier> | null> {
        return await ajaxCall.baseAjaxCall<ISupplier>('get', this.url, { urlParameter: [id], queryParameter: { code } });
    }

    public async exportStudiosData(): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, string>('post', this.url, {
            urlParameter: ['exportStudiosData'],
            responseType: 'blob'
        });
    }

    public async exportReferBankData(): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, string>('post', this.url, {
            
            urlParameter: ['exportReferBankData'],
            responseType: 'blob'
        });
    }

    public async exportCatEmployesData(): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, string>('post', this.url, {
            urlParameter: ['exportCatEmployesData'],
            responseType: 'blob'
        });
    }

    public async exportAgencesData(): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, string>('post', this.url, {
            urlParameter: ['exportAgencesData'],
            responseType: 'blob'
        });
    }

    public async exportSalesAccountData(): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, string>('post', this.url, {
            urlParameter: ['exportSalesAccountData'],
            responseType: 'blob'
        });
    }

    public async exportInsuranceTypesData(): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, string>('post', this.url, {
            urlParameter: ['exportInsuranceTypesData'],
            responseType: 'blob'
        });
    }
}

export const referentialApi: ReferentialApi = new ReferentialApi();
